import UserInterface from './UserInterface';
import { UserRoleListType } from '@/resources/UserRoleListType';

export default class implements UserInterface {
    readonly sub: string = "";
    readonly eMailAddress: string = "";
    readonly fullName: string = "";
    readonly customerID: string = "";
    readonly language: string = "";
    readonly enabled: boolean = false;
    readonly initialSettingsDone: boolean = false;
    get userRoleList(): string[]{
        return this._userRoleList;
    }
    set userRoleList(value: string[]){
        this._userRoleList = value;
    }
    readonly loginHistory: string[] = [];
    readonly isSSOUser: boolean = false;
    readonly lastUseDate: string = "";
    readonly lastUseTime: string = "";
    readonly creationDate: string = "";
    readonly creationTime: string = "";
    private _userRoleList: string[] = [];
    get isUserManager(): boolean {
        return this._userRoleList?.includes(UserRoleListType.USER_MANAGER);
    }
    get isTranslationManager(): boolean {
        return this._userRoleList?.includes(UserRoleListType.TRANSLATION_MANAGER);
    }
    constructor(props: {
        sub?: string;
        eMailAddress?: string;
        fullName?: string;
        customerID?: string;
        language?: string;
        enabled?: boolean;
        initialSettingsDone?: boolean;
        userRoleList?: string[];
        loginHistory?: string[];
        isSSOUser?: boolean;
        lastUseDate?: string;
        lastUseTime?: string;
        creationDate?: string;
        creationTime?: string;
    }){
        if(props.sub) this.sub = props.sub;
        if(props.eMailAddress) this.eMailAddress = props.eMailAddress;
        if(props.fullName) this.fullName = props.fullName;
        if(props.customerID) this.customerID = props.customerID;
        if(props.language) this.language = props.language;
        if(props.enabled) this.enabled = props.enabled;
        if(props.initialSettingsDone) this.initialSettingsDone = props.initialSettingsDone;
        if(props.userRoleList) this._userRoleList = props.userRoleList;
        if(props.loginHistory) this.loginHistory = props.loginHistory;
        if(props.isSSOUser) this.isSSOUser = props.isSSOUser;
        if(props.lastUseDate) this.lastUseDate = props.lastUseDate;
        if(props.lastUseTime) this.lastUseTime = props.lastUseTime;
        if(props.creationDate) this.creationDate = props.creationDate;
        if(props.creationTime) this.creationTime = props.creationTime;
    }
    
}