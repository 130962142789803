import { ref } from 'vue';
import awsExports from '@/aws-exports';
import { Auth } from 'aws-amplify';
import * as Sentry from '@sentry/browser'
import { createRouter, createWebHistory, RouteRecordRaw, RouteLocationNormalized, NavigationGuardNext, PostNavigationGuard } from 'vue-router'
import i18n, { GetLocale } from '@/plugins/i18n';
import LoadMyAccount from '@/usecases/user/LoadMyAccount';
import LoadContracts from '@/usecases/contract/LoadContracts';
import LoadGlossaries from '@/usecases/glossary/LoadGlossaries';
import { MTransApplication, getMTransApplicationName } from '@/resources/MTransApplication';
import { toPascalCase } from '@/entities/Utility';
import SaveMyLanguage from '@/usecases/user/SaveMyLanguage';
import { useGlobalError } from '@/composables/global-error';
import APIError from '@/entities/APIError';

const nameOfMTransForOffice = getMTransApplicationName(MTransApplication.MTransForOffice);
const nameOfMTransForPhraseTMS = getMTransApplicationName(MTransApplication.MTransForPhraseTMS);
const nameOfMTransForTrados = getMTransApplicationName(MTransApplication.MTransForTrados);
const nameOfMTransForCrowdin = getMTransApplicationName(MTransApplication.MTransForCrowdin);
const nameOfMTransAPI = getMTransApplicationName(MTransApplication.MTransAPI);

export const routes: Array<RouteRecordRaw> = [
  {
    path: '/', 
    name: 'Index', 
    component: () => import(/* webpackChunkName: "index" */ '../views/Index.vue'), 
    meta : { requiresAuth: true, showsBreadcrumbs: false }, 
    redirect : {name: 'Settings'},
    children: 
    [
      {
        path: 'error',
        name: 'Error',
        component: () => import(/* webpackChunkName: "errir" */ '../views/error/Index.vue'),
        meta : { title: 'Error', hasNoHeader: true }, 
      },
      {
        path: 'initial-settings', 
        name: 'InitialSettings', 
        component: () => import(/* webpackChunkName: "initialSettings" */ '../views/initial-settings/Index.vue'),
        props: (route) => ({
          emailAddress: route.params.e,
          password: route.params.p,
        }),
        meta: { title: 'Initial Settings'}
      },
      { 
        path: 'settings', 
        // Setting path to redirect might bring about errors.
        // https://stackoverflow.com/questions/55484090/vue-router-default-child-route-not-loading-initially
        // redirect: { name: 'Download'}, 
        name: 'Settings', 
        component: () => import(/* webpackChunkName: "settings" */ '../views/settings/Index.vue'), 
        meta: {requiresInitialSettings: true},
        children:
        [
          {
            path: 'download/',
            name: 'Download',
            component: () => import(/* webpackChunkName: "download" */ '../views/download/Index.vue'), 
            meta: {requiresInitialSettings: true},
            redirect: { name: toPascalCase(nameOfMTransForOffice)},
            children: [
              {
                path: 'mtrans-for-office',
                name: toPascalCase(nameOfMTransForOffice),
                component: () => import(/* webpackChunkName: "mtransForOffice" */ '../views/download/mtrans-for-office/Index.vue'),
                meta: { title: `${nameOfMTransForOffice}（Windows）` }
              }
            ] 
          },
          {
            path: 'apps', 
            // Setting path to redirect might bring about errors.
            // https://stackoverflow.com/questions/55484090/vue-router-default-child-route-not-loading-initially
            name: 'Apps', 
            component: () => import(/* webpackChunkName: "apps" */ '../views/settings/apps/Index.vue'), 
            meta: {requiresInitialSettings: true},
            redirect: { name: toPascalCase(nameOfMTransForPhraseTMS)},
            children: [
              {
                path: 'mtrans-for-office/ofad',
                name: `${toPascalCase(nameOfMTransForOffice)}OFAD`,
                component: () => import(/* webpackChunkName: "mtransForOffice" */ '../views/settings/apps/mtrans-for-office/ofad/Index.vue'),
                meta: { title: `${nameOfMTransForOffice}（Mac/Web）` }
              },
              {
                path: 'mtrans-for-phrase-tms',
                name: toPascalCase(nameOfMTransForPhraseTMS),
                component: () => import(/* webpackChunkName: "mtransForPhraseTMS" */ '../views/settings/apps/mtrans-for-phrase-tms/Index.vue'),
                meta: { title: nameOfMTransForPhraseTMS }
              },
              {
                path: 'mtrans-for-memsource',
                redirect: { name: toPascalCase(nameOfMTransForPhraseTMS)},
              },
              {
                path: 'mtrans-for-trados',
                name: toPascalCase(nameOfMTransForTrados),
                component: () => import(/* webpackChunkName: "mtransForTrados" */ '../views/settings/apps/mtrans-for-trados/Index.vue'),
                meta: { title: nameOfMTransForTrados }
              },
              {
                path: 'mtrans-for-crowdin',
                name: toPascalCase(nameOfMTransForCrowdin),
                component: () => import(/* webpackChunkName: "mtransForCrowdin" */ '../views/settings/apps/mtrans-for-crowdin/Index.vue'),
                meta: { title: nameOfMTransForCrowdin }
              }
            ]
          },
          {
            path: 'apis', 
            // Setting path to redirect might bring about errors.
            // https://stackoverflow.com/questions/55484090/vue-router-default-child-route-not-loading-initially
            name: 'APIs', 
            component: () => import(/* webpackChunkName: "apis" */ '../views/settings/apis/Index.vue'), 
            meta: {requiresInitialSettings: true},
            redirect: { name: toPascalCase(nameOfMTransAPI)},
            children: [
              {
                path: 'mtrans-api',
                name: toPascalCase(nameOfMTransAPI),
                component: () => import(/* webpackChunkName: "mtransAPI" */ '../views/settings/apis/mtrans-api/Index.vue'),
                meta: { title: nameOfMTransAPI }
              }
            ]
          },
          {
            path: 'my-account', 
            name: 'MyAccount',
            component: () => import(/* webpackChunkName: "myAccount" */ '../views/settings/my-account/Index.vue'),
            meta: { title: 'My Account - Settings'}
          },
          {
            path: 'company',
            name: 'Company',
            component: () => import(/* webpackChunkName: "company" */ '../views/settings/company/Index.vue'),
            meta: {requiresUserAdmin: true},
            redirect: { name: 'Users'}, 
            children :
            [
              {
                path: 'users', 
                name: 'Users', 
                component: () => import(/* webpackChunkName: "users" */ '../views/settings/company/users/Index.vue'),
                meta: { title: 'Users'}
              },
              {
                path: 'contracts', 
                name: 'Contracts', 
                component: () => import(/* webpackChunkName: "contracts" */ '../views/settings/company/contracts/Index.vue'),
                meta: { title: 'Contracts'}
              },
              {
                path: 'usage-history', 
                name: 'UsageHistory', 
                component: () => import(/* webpackChunkName: "usage-history" */ '../views/settings/company/usage-history/Index.vue'),
                meta: { title: 'Usage History'},
                redirect: { name: 'MTUsage'}, 
                children : [
                  {
                    path: 'mt-usage', 
                    name: 'MTUsage', 
                    component: () => import(/* webpackChunkName: "mt-usage" */ '../views/settings/company/usage-history/mt-usage/Index.vue'),
                    meta: { title: 'MT Usage'}
                  },
                  {
                    path: 'mtrans-online-usage', 
                    name: 'MTransOnlineUsage', 
                    component: () => import(/* webpackChunkName: "mtrans-online-usage" */ '../views/settings/company/usage-history/mtrans-online-usage/Index.vue'),
                    meta: { title: 'MTrans Online Usage'}
                  },
                ]
              },
            ]
          },
        ]
      },
      {
        path: 'glossaries',
        name: 'Glossaries',
        component: () => import(/* webpackChunkName: "glossaries" */ '../views/glossaries/Index.vue'), 
        meta: { title: 'Glossaries', requiresGlossaryContract: true },        
        children:
        [
          {
            path: ':glossary_id([^/]+?)/terms',
            name: 'GlossaryTerms',
            component: () => import(/* webpackChunkName: "glossaryTerms" */ '../views/glossaries/terms/Index.vue'),
            meta: { title: 'Glossary Terms', showsBreadcrumbs: true},
          },
          {
            path: ':glossary_id([^/]+?)/details',
            name: 'GlossaryDetails',
            component: () => import(/* webpackChunkName: "glossaryDetails" */ '../views/glossaries/Detail.vue'),
            meta: { title: 'Glossary Detail', showsBreadcrumbs: true},
          },
        ]
      },
      {
        path: 'text-processing-sets',
        component: () => import(/* webpackChunkName: "glossaries" */ '../views/text-processing-sets/Index.vue'), 
      }
    ]
  },
  { 
    path: '/login', 
    name: 'Login', 
    component: () => import(/* webpackChunkName: "login" */ '../views/login/Index.vue'),
    meta: { title: 'Login'}
  },
]

export interface RoutePath {
  path: string;
  name: string;
  fullPath: string;
}
export const getFlattenRoutes = (): RoutePath[] => {
  const getRouteListFromChildren = (self: RouteRecordRaw, parentFullPath: string): RoutePath[] => {
    let childRoutes: RoutePath[] = [];    
    const formattedParentFullPath = ["", "/"].includes(parentFullPath) ? parentFullPath : `${parentFullPath}/`;
    const selfFullPath = `${formattedParentFullPath}${self.path}`;
    if(self.children) {
      childRoutes = self.children.map(r => getRouteListFromChildren(r, selfFullPath)).flat();
    }     
    return [
      {
        path: self.path,
        name: String(self.name),
        fullPath: selfFullPath
      }, 
      ...childRoutes
    ];
  }
  return routes.map(r => getRouteListFromChildren(r, "")).flat();
}

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router

export class RouterGuard {
  
  public readonly isRouting = ref(false);  
  private static routerGuard = new RouterGuard();
  public static getInstance() {
    return this.routerGuard;
  }

  get(): {
    beforeEach: (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => Promise<void>;
    afterEach: PostNavigationGuard;
  } {
    return {
      beforeEach: this.guard,
      afterEach: async (to: RouteLocationNormalized, from: RouteLocationNormalized) => {
        const rg = RouterGuard.getInstance();
        rg.isRouting.value = false;
        document.title = to.meta.title || "MTrans";
      }
    };
  }

  private async isAuthenticatedUser() {
    try{
      await Auth.currentAuthenticatedUser();        
      return true;
    }catch {
      return false;
    }
  }

  private async loadMyAccountAsync() {    
    let myAccount = await new LoadMyAccount().handleAsync();
    Sentry.setUser({id: myAccount.sub});
    Sentry.setTag("customer", myAccount.customerID);
    // #3541
    if(!myAccount.language) {
      await new SaveMyLanguage().handleAsync(window.navigator.language !== 'ja' ? 'en' : 'ja');
      myAccount = await new LoadMyAccount().handleAsync();
    }
    i18n.global.locale.value = GetLocale(myAccount.language);
    return myAccount;
  }

  private async getAppPageNameToRedirectToAsync() {
    const contractsInProgress = (await new LoadContracts().handleAsync({filterInProgress:true})).contractInterfaces;
    if(contractsInProgress.length === 0) {
      return toPascalCase(nameOfMTransForOffice)
    } else {
      if(contractsInProgress.some(c => c.hasMTransForOffice)) {
        const pascalName = toPascalCase(nameOfMTransForOffice);
        return window.navigator.userAgent.toLowerCase().indexOf("windows nt") !== -1 ? pascalName : `${pascalName}OFAD`;
      } else if (contractsInProgress.some(c => c.hasMTransForPhraseTMS)) {
        return toPascalCase(nameOfMTransForPhraseTMS)
      } else if (contractsInProgress.some(c => c.hasMTransForTrados)) {
        return toPascalCase(nameOfMTransForTrados)
      } else if (contractsInProgress.some(c => c.hasMTransAPI)) {
        return toPascalCase(nameOfMTransForCrowdin)
        // return toPascalCase(nameOfMTransAPI)
      } else {
        return toPascalCase(nameOfMTransForOffice)
      }
    }    
  }

  private async validateGlossaryContractAsync() {
    const contractsInProgress = (await new LoadContracts().handleAsync({filterInProgress:true})).contractInterfaces;
    return contractsInProgress.some(c => c.hasTermAdaptation);
  }
  
  private async getGlossaryAsync(glossaryID: string){
    const glossaries = await new LoadGlossaries().handleAsync();
    const glossary = glossaries.find(g => g.glossaryID === glossaryID);    
    return glossary;
  }

  private async guard(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
    
    const globalError = useGlobalError();
    const rg = RouterGuard.getInstance();    
    rg.isRouting.value = true;
      
    if(to.matched.some(record => record.meta.requiresAuth))
    {
      const isAuthenticated = await rg.isAuthenticatedUser();

      if(isAuthenticated) {
        if(to.name === 'Error') {
          if(globalError.hasGlobalError) {
            next()
          } else {
            next({ name: 'Index' });
          }
        } else {
          try {
            const myAccount = await rg.loadMyAccountAsync();
            if(to.matched.some(record => record.meta.requiresUserAdmin)){
              if(myAccount.isUserManager) {
                next();
              } else {
                next({ name: 'Index' })
              }        
            } else {
              if(to.name === 'InitialSettings') {
                next({ name: 'Index' });            
              } else {            
                if(to.name === 'Settings') {
                  const name = await rg.getAppPageNameToRedirectToAsync();
                  next({ name })
                } else if(to.matched.some(record => record.meta.requiresGlossaryContract)) {              
                  const hasGlossaryContract = await rg.validateGlossaryContractAsync();              
                  if(hasGlossaryContract) {                
                    if(to.matched.some(record => record.name === 'GlossaryTerms' || record.name === 'GlossaryDetails')) {
                      try {
                        if(!to.meta.name) {
                          const glossary = await rg.getGlossaryAsync(`${to.params['glossary_id']}`);
                          to.meta.name = glossary?.glossaryName;
                        }
                        next();
                      } catch (error) {
                        next({ name: 'Glossaries' });
                      }
                    } else {
                      next();
                    }
                  } else {
                    next({ name: 'Index' })
                  }
                } else {
                  next();
                }
              }
            }          
          } catch (error) {
            globalError.httpStatus = (error as APIError).response.status;
            globalError.errorCode = (error as APIError).code;
            next({ name: 'Error' })
          } 
        }
      } else {
        
        if(awsExports.Auth.userPoolWebClientId !== process.env.VUE_APP_AWS_AUTH_USER_POOL_WEB_CLIENT_ID){
          localStorage.removeItem(`${process.env.VUE_APP_KEY_CLIENT_ID}`);
          Auth.federatedSignIn();
          return;
        }

        if(to.matched.some(record => record.meta.requiresInitialSettings)){
          next({
            name: 'Login',
          })
        } else {
          if(to.name === 'InitialSettings' && to.params.challengeName === "NEW_PASSWORD_REQUIRED"){
            next();
          } else {
            next({
              name: 'Login',
            })
          }
        } 
  
      }
    }
    else 
    {
      next();
    }
  }  
}
